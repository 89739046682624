/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-unused-expressions */
const removeChatUI = (): void => {
  document.getElementById('designstudio')?.remove()
  document.getElementById('designstudio-minimize')?.remove()
  document.getElementById('designstudio-button')?.remove()
}

export const loadDefaultSE = (): void => {
  const defaultSESnippet = `
        window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-458565-9', {
          send_page_view: false
      });
      (function() {
      var se = document.createElement('script'); se.type = 'text/javascript'; se.async = true;
      se.src = "https://storage.googleapis.com/code.snapengage.com/js/79fd73b0-6ae3-4f17-a71b-4c063461ce4b.js";
      var done = false;
      se.onload = se.onreadystatechange = function() {
          if (!done&&(!this.readyState||this.readyState==='loaded'||this.readyState==='complete')) {
          done = true;
          /* Place your SnapEngage JS API code below */
          /* SnapEngage.allowChatSound(true); Example JS API: Enable sounds for Visitors. */
              SnapEngage.setCallback("ChatMessageReceived", function(agent, msg) {
                  gtag("event", "Chat Message Received", {
                  event_label: agent,
                  event_category: "SnapEngage",
                  non_interaction: true
                  });
              });
              SnapEngage.setCallback("ChatMessageSent", function(msg) {
                  gtag("event", "Chat Message Sent", {
                  event_label: msg,
                  event_category: "SnapEngage",
                  non_interaction: true
                  });
              });
              SnapEngage.setCallback("MessageSubmit", function(email, msg) {
                  gtag("event", "Message Submit", {
                  event_label: msg,
                  event_category: "SnapEngage",
                  non_interaction: true
                  });
              });
              SnapEngage.setCallback("Open", function(status) {
                  gtag("event", "Open Chat", {
                  event_label: status,
                  event_category: "SnapEngage",
                  non_interaction: true
                  });
              });
              SnapEngage.setCallback("StartChat", function(email, msg, type) {
                  gtag("event", "Start Chat", {
                  event_label: msg,
                  event_category: "SnapEngage",
                  non_interaction: true
                  });
              });
          }
      };
      var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(se, s);
      })()
    `

  if (document.getElementById('defaultSE')) {
    return
  }

  const container = document.createElement('div')
  container.setAttribute('id', 'defaultSE')

  const script = document.createElement('script')
  script.text = defaultSESnippet

  container.appendChild(script)
  document.body.appendChild(container)
}

export const removeDefaultSE = (): void => {
  const defaultSnapEngage = document.getElementById('defaultSE')

  if (defaultSnapEngage) {
    defaultSnapEngage.remove()
    removeChatUI()
    loadAfterSalesSE()
  }
}

export const QASnippet = `
  (function() {
    var afse = document.createElement('script'); afse.type = 'text/javascript'; afse.async = true;
    afse.src = "https://ayalaland--uat.sandbox.my.site.com/ESWRBGAllBrand1714307082597/assets/js/bootstrap.min.js";
    var done = false;
    afse.onload = afse.onreadystatechange = function() {
      if (!done&&(!this.readyState||this.readyState==='loaded'||this.readyState==='complete')) {
        done = true;
        try {
          embeddedservice_bootstrap.settings.language = 'en_US'; // For example, enter 'en' or 'en-US'
          window.addEventListener("onEmbeddedMessagingReady", e => {
            embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
              Brand: "AVIDA"
            });
          }); 
    
          embeddedservice_bootstrap.init(
            '00DF8000000ElTI',
            'RBG_All_Brand',
            'https://ayalaland--uat.sandbox.my.site.com/ESWRBGAllBrand1714307082597',
            {
              scrt2URL: 'https://ayalaland--uat.sandbox.my.salesforce-scrt.com'
            }
          );
        } catch (err) {
          console.error('Error loading Embedded Messaging: ', err);
        }
      }
    };
  var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(afse, s);
  })()
`

export const ProdSnippet = `
  (function() {
    var afse = document.createElement('script'); afse.type = 'text/javascript'; afse.async = true;
    afse.src = "https://ayalaland.my.site.com/ESWRBGAllBrand1716003052216/assets/js/bootstrap.min.js";
    var done = false;
    afse.onload = afse.onreadystatechange = function() {
      if (!done&&(!this.readyState||this.readyState==='loaded'||this.readyState==='complete')) {
      done = true;
      try {
        embeddedservice_bootstrap.settings.language = 'en_US'; // For example, enter 'en' or 'en-US'
        window.addEventListener("onEmbeddedMessagingReady", e => {
          embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
            Brand: "AVIDA"
          });
        }); 
        embeddedservice_bootstrap.init(
          '00D28000001Gnqh',
          'RBG_All_Brand',
          'https://ayalaland.my.site.com/ESWRBGAllBrand1716003052216',
          {
            scrt2URL: 'https://ayalaland.my.salesforce-scrt.com'
          }
        );
      } catch (err) {
        console.error('Error loading Embedded Messaging: ', err);
      }
    }
  };
  var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(afse, s);
  })()
`

export const loadAfterSalesSE = (): void => {
  const afterSalesSESnippet = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-458565-9', {
          send_page_view: false
      });
      ${process.env.GATSBY_SITE_URL?.includes('qa') ? QASnippet : ProdSnippet}
  `

  if (document.getElementById('defaultSE')) {
    return
  }

  const container = document.createElement('div')
  container.setAttribute('id', 'afterSalesSE')

  const script = document.createElement('script')
  script.text = afterSalesSESnippet

  container.appendChild(script)
  document.body.appendChild(container)
}

export const removeAfterSalesSE = (): void => {
  const afterSalesSE = document.getElementById('afterSalesSE')

  if (afterSalesSE) {
    afterSalesSE.remove()
    removeChatUI()
    loadDefaultSE()
  }
}

export const removeChatbot = (): void => {
  const chatBubble: HTMLElement | null = document.getElementById(
    'designstudio-button',
  )
  if (chatBubble) chatBubble.style.display = 'none'
}

export const showChatbot = (): void => {
  const chatBubble: HTMLElement | null = document.getElementById(
    'designstudio-button',
  )
  if (chatBubble) chatBubble.style.display = 'block'
}
