import { Link } from 'gatsby'
import React, { memo, ReactElement, useEffect, useRef, useState } from 'react'
import './ContentNavigation.scss'

export type ContentNavigationTypes = {
  label: string
  url?: string
  items?: ContentNavigationTypes[]
  isTypeScroll?: boolean
}

type ContentNavigationPropTypes = {
  navigationPrefix?: string
  navigations: ContentNavigationTypes[]
  className?: string
  center?: boolean
  isTypeScroll?: boolean
}

const ContentNavigationItem = ({
  label,
  url,
  items,
  isTypeScroll = false,
  navigationPrefix,
}: ContentNavigationTypes & { navigationPrefix?: string }): JSX.Element => {
  const [isSubOpened, setIsSubOpened] = useState(false)

  if (isTypeScroll) {
    return (
      <li>
        <Link to={`#${url}`} activeClassName="active">
          {label}
        </Link>
      </li>
    )
  }

  return (
    <li>
      {url || url === '' ? (
        <Link
          to={`${isTypeScroll ? `#${url}` : ''}/${
            navigationPrefix ? `${navigationPrefix}/` : ''
          }${url ? `${url}/` : ''}`}
          activeClassName="active"
        >
          {label}
        </Link>
      ) : (
        <>
          <button
            type="button"
            onClick={(): void => setIsSubOpened(!isSubOpened)}
            className={isSubOpened ? 'active' : ''}
          >
            {label}
          </button>
          <div
            className={`content-navigation-other-categories-container${
              isSubOpened ? ' active' : ''
            }`}
            role="button"
            onClick={(): void => setIsSubOpened(!isSubOpened)}
            onKeyPress={(): void => setIsSubOpened(!isSubOpened)}
            tabIndex={0}
          >
            <div className="content-navigation-other-categories">
              <h3 className="content-navigation-other-categories-title">
                Other Categories
              </h3>
              <h3 className="content-navigation-other-categories-title-mobile">
                {label}
              </h3>
              {!!items && items.length && (
                <ul>
                  {items.map((item) => (
                    <li key={item.label}>
                      <Link to={`/${item.url}/`} activeClassName="active">
                        {item.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </>
      )}
    </li>
  )
}

const ContentNavigation = ({
  navigationPrefix,
  navigations,
  className,
  center,
  isTypeScroll,
}: ContentNavigationPropTypes): ReactElement => {
  const navigationRef = useRef<HTMLUListElement>(null)

  useEffect(() => {
    if (navigationRef && navigationRef.current !== null) {
      navigationRef.current.childNodes.forEach((child) => {
        const linkElement = child.firstChild as HTMLAnchorElement

        if (linkElement && linkElement.className === 'active') {
          linkElement.scrollIntoView({
            block: 'center',
            inline: 'center',
          })
        }
      })
    }
  }, [])

  return (
    <ul
      id="content-navigation"
      ref={navigationRef}
      className={`content-navigation${center ? ' center' : ''} ${
        className ? `${className} ` : ''
      }`}
    >
      {navigations?.map(({ url, label, items }) => (
        <ContentNavigationItem
          key={label}
          url={url}
          label={label}
          items={items}
          navigationPrefix={navigationPrefix}
          isTypeScroll={isTypeScroll}
        />
      ))}
    </ul>
  )
}

export default memo(ContentNavigation)
