import React, { ReactElement, useState, useCallback, useEffect } from 'react'
import { graphql } from 'gatsby'
import loadable from '@loadable/component'
import Layout from '../../layouts/Layout/Layout'
import './CareerCorporate.scss'
import NAVIGATIONS from '../../data/career-tabs.json'

import LandingPageLayout from '../../layouts/LandingPageLayout/LandingPageLayout'
import Seo from '../../components/Seo/Seo'
import CareerBanner from '../../assets/images/careers-page-banner.png'
import { removeChatbot, showChatbot } from '../../services/snapengage'

const CareerCard = loadable(() =>
  import('../../components/CareerCard/CareerCard'),
)

const Pagination = loadable(() =>
  import('../../components/Pagination/Pagination'),
)

const NoResult = loadable(() => import('../../components/NoResult/NoResult'))

export type CareerTypes = {
  id: string
  title: string
  description: string
  postedAt: string
  postedDate: string
  applyBeforeDate: string
  applyBeforeDateFull: string
  jobDescription: string
  qualifications: string
  location: string
  slug: string
  tags: string[]
  employmentType: {
    name: string
  }
}

type MainPropTypes = {
  data: {
    allCareer: {
      nodes: CareerTypes[]
    }
  }
  pageContext: {
    filterType: string
    activeLabel: string
  }
}
export const ARTICLE_PER_PAGE = 9

const CareerOpportunityList = ({
  data: {
    allCareer: { nodes: allCareer },
  },
  pageContext: { filterType, activeLabel },
}: MainPropTypes): ReactElement => {
  const [dataList, setDataList] = useState<CareerTypes[]>(allCareer)
  const [pageDataList, setPageDataList] = useState<CareerTypes[]>(
    dataList.slice(0, ARTICLE_PER_PAGE),
  )
  const [pages, setPages] = useState(
    Math.ceil(dataList.length / ARTICLE_PER_PAGE),
  )
  const [currentPage, setCurrentPage] = useState(1)
  const [currentSets, setCurrentSets] = useState(1)

  const handlePageClick = useCallback((page: number): void => {
    window.scrollTo({
      top: 300,
    })
    setCurrentPage(page)
  }, [])

  useEffect(() => {
    removeChatbot()

    return (): void => {
      showChatbot()
    }
  }, [])

  useEffect(() => {
    setPages(Math.ceil(dataList.length / ARTICLE_PER_PAGE))
  }, [dataList])

  const handleAddSets = useCallback((): void => {
    const newSetCount = currentSets + 1
    setPageDataList(dataList.slice(0, newSetCount * ARTICLE_PER_PAGE))
    setCurrentSets(newSetCount)
  }, [currentSets, dataList])

  useEffect(() => {
    setPageDataList(
      dataList.slice(
        (currentPage - 1) * ARTICLE_PER_PAGE,
        currentPage * ARTICLE_PER_PAGE,
      ),
    )
  }, [currentPage, dataList])

  useEffect(() => {
    setCurrentPage(1)
    setCurrentSets(1)

    setDataList(
      allCareer.filter((data) => data.employmentType.name === filterType),
    )
  }, [allCareer, filterType])

  return (
    <Layout>
      <Seo
        jsonData={{
          url: `${process.env.GATSBY_SITE_URL}/careers/corporate/`,
          keywords:
            'properties, condo, philippines, house and lot, avidaland, avida, apartment, careers, avidaland careers, corporate, corporate team',
        }}
        title="Career Opportunities | Corporate Team"
      />
      <div className="career-list">
        <LandingPageLayout
          title="Career Opportunities"
          tabNavigation={NAVIGATIONS}
          activeLabel={activeLabel}
          banner={CareerBanner}
          body="We are constantly on the lookout for talented and passionate individuals with a relentless drive to contribute to Avida’s mission of making sustainable dream communities a reality for Filipinos. Explore our career opportunities and be part of the team."
        >
          <h2 className="career-list-header">Be part of our {activeLabel}.</h2>
          <div className="career-list-content">
            {pageDataList.length ? (
              pageDataList.map((card) => (
                <CareerCard key={card.id} data={card} />
              ))
            ) : (
              <NoResult />
            )}
          </div>
        </LandingPageLayout>
      </div>
      <div className="career-list-pagination">
        <Pagination
          pages={pages}
          currentPage={currentPage}
          onPageClick={handlePageClick}
        />
      </div>
      {currentSets < pages && (
        <div className="career-list-load-more">
          <button
            type="button"
            className="career-list-load-more-button"
            onClick={handleAddSets}
          >
            Load More
          </button>
        </div>
      )}
    </Layout>
  )
}

export default CareerOpportunityList

export const pageQuery = graphql`
  query {
    allCareer {
      nodes {
        ...CareerFields
      }
    }
  }
`
