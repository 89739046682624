import React, { ReactElement } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import './LandingPageLayout.scss'
import ContentNavigation, {
  ContentNavigationTypes,
} from '../../components/ContentNavigation/ContentNavigation'
import FacebookIcon from '../../assets/svg/fb-red.svg'
import InstagramIcon from '../../assets/svg/insta-red.svg'
import YoutubeIcon from '../../assets/svg/yt-red.svg'
import TiktokIcon from '../../assets/svg/tiktok-red.svg'
import LinkedInIcon from '../../assets/svg/linkedin-red.svg'

type TabTypes = {
  label: string
  path: string
}

type LandingPageProps = {
  header?: string
  title: string
  body: string
  banner?: string
  isSearch?: boolean
  className?: string
  children?:
    | string
    | Element
    | ReactElement
    | boolean
    | (string | Element | ReactElement | boolean)[]
  tabNavigation?: ContentNavigationTypes[]
  activeLabel?: string
  background?: string
}

type LinkTypes = {
  url: string
}

type SocialMediaTypes = {
  facebook?: LinkTypes
  instagram?: LinkTypes
  youtube?: LinkTypes
  tiktok?: LinkTypes
  linkedin?: LinkTypes
}

const LandingPageLayout = ({
  header,
  title,
  banner,
  body,
  isSearch,
  className,
  children,
  tabNavigation,
  activeLabel,
  background,
}: LandingPageProps): ReactElement => {
  const {
    facebook,
    instagram,
    youtube,
    tiktok,
    linkedin,
  }: SocialMediaTypes = useStaticQuery(
    graphql`
      query SocialMediaQuery {
        facebook: link(
          category: { eq: "social_media" }
          name: { eq: "facebook" }
        ) {
          ...LinkFields
        }
        instagram: link(
          category: { eq: "social_media" }
          name: { eq: "instagram" }
        ) {
          ...LinkFields
        }
        youtube: link(
          category: { eq: "social_media" }
          name: { eq: "youtube" }
        ) {
          ...LinkFields
        }
        tiktok: link(category: { eq: "social_media" }, name: { eq: "tiktok" }) {
          ...LinkFields
        }
        linkedin: link(
          category: { eq: "social_media" }
          name: { eq: "linkedin" }
        ) {
          ...LinkFields
        }
      }
    `,
  )

  return (
    <div className={`landing-page-layout${className ? `${className} ` : ''}`}>
      <div
        className="landing-page-layout-overlay"
        style={{
          background: `linear-gradient(to bottom, rgba(153, 13, 21, 0.8), rgba(153, 13, 21, 0.8)), url('${background}') no-repeat scroll center center / cover, linear-gradient(to bottom, #990d14d7, #990d14d7)`,
          height: banner && 'auto',
        }}
      >
        <h3 className="landing-page-layout-overlay-header">{header}</h3>
        {banner && (
          <img
            src={banner}
            alt="banner image"
            className="landing-page-layout-overlay-banner"
          />
        )}
        {header ? (
          <h1 className="landing-page-layout-overlay-title-default">{title}</h1>
        ) : (
          <h1 className="landing-page-layout-overlay-title">{title}</h1>
        )}
        <p className="landing-page-layout-overlay-body">{body} </p>
        {isSearch && (
          <div className="landing-page-layout-overlay-search-container">
            {children}
          </div>
        )}
      </div>
      {!!tabNavigation && (
        <div className="landing-page-layout-content">
          <div className="landing-page-layout-social-container">
            {!!facebook && (
              <a
                href={facebook.url}
                className="landing-page-layout-social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={FacebookIcon} alt="Facebook" />
              </a>
            )}
            {!!instagram && (
              <a
                href={instagram.url}
                className="landing-page-layout-social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={InstagramIcon} alt="Instagram" />
              </a>
            )}
            {!!youtube && (
              <a
                href={youtube.url}
                className="landing-page-layout-social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={YoutubeIcon} alt="Youtube" />
              </a>
            )}
            {!!tiktok && (
              <a
                href={tiktok.url}
                className="landing-page-layout-social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={TiktokIcon} alt="Tiktok" />
              </a>
            )}
            {!!linkedin && (
              <a
                href={linkedin.url}
                className="landing-page-layout-social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={LinkedInIcon} alt="LinkedIn" />
              </a>
            )}
          </div>
          <ContentNavigation navigations={tabNavigation} center />

          <div className="landing-page-layout-content-main">{children}</div>
        </div>
      )}
    </div>
  )
}

export default LandingPageLayout
